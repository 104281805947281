@page {
    /*padding: 5px;*/
    size:325px 180px;
    /*margin: 5px;*/

}
.print-container {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.print-container p {
    padding: 0;
    margin: 0;
}

.print-container{
    display: flex;
    width: 445px;
    height: 250px;
    border: 2px solid;

}
.left-table{
    padding: 5px;
    max-width: 35%;
    border-right: 2px solid;
}

.courier-logo{
    max-width: 100%;
    max-height: 100px;
}

.company-number{
    font-size: 14px;
    text-align: center;
}

.check-number{
    margin-top: 20px;
    font-weight: bold;
    font-size: 16px;
}

.qr-code{
    margin-top: 8px;
    width: 110px;
    height: 110px;
}

/*.logo{*/
/*    margin-top: 10px;*/
/*    max-width: 100%;*/
/*    max-height: 100px;*/

/*}*/

/*right table*/

.right-table{
    width: 65%;
    display: flex;
    flex-direction: column;
}

.sender-table {
    border-bottom: 2px solid;
    padding: 0 10px;
    min-height: 85px;
}

.call{
    display: flex;
    align-items: center;
    /*margin: 5px 0;*/
}

.phone-number{
    font-size: 20px;
    font-weight: bold;
    padding-left: 10px;
}
.call img{
    max-width: 100%;
    max-height: 15px;
}

.info-sender-receiver{
    font-size: 18px;
    padding: 10px 0 0;
}

.info-address{
    font-size: 18px;
    padding-bottom: 3px;
}

.receiver-table{
    border-bottom: 2px solid;
    padding: 0 10px;
    min-height: 85px;
}

.comment-table{
    padding: 5px;
}

.comments-wrapper{
    position: relative;
}
.prices{
    margin-top: 0px;
    display: flex;
    justify-content: space-around;
}

.comments{
    display: flex;
}

.comments img{
    position: absolute;
    max-width: 100%;
    max-height: 25px;
}
.comments-text{
    padding-top: 3px;
    text-indent: 30px;
    font-size: 14px;
    padding-left: 10px;
    padding-bottom: 5px;
}


