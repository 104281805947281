// Here you can add other styles

.sp-package-mini {
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

.sp-table {
  margin-bottom: -1rem;
}

.sp-tooltip {
  width: 180px;
  height: 35px;
}

.sp-rounded {
  border-radius: 75%;
  border: 2px solid rgb(55, 190, 55);
}

.phoneInput {
  width: 100% !important;
}

.sp-report::before {
  content: url(../assets/icons/excel.png);
}

.sp-act::before {
  content: url(../assets/icons/xls.png);
}

.sp_error {
  color: rgb(226, 66, 61);
  font-size: 16px;
}

.order_table_row:hover {
  background-color:rgb(170, 168, 250) !important;
}

.ats-table {
  tr td {
    padding: 2px;
  }
}

.sidebarSearch[type="text"]
{
    background: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0%;
}

@media print {
  .pagebreak { page-break-before: always; } /* page-break-after works, as well */
}

.sp-link:hover {
  cursor: pointer;
}